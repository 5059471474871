import React, { useEffect } from "react";
import { Redirect } from "@reach/router";

import Layout from "@components/layout";
import Seo from "@components/seo";

const Boost = () => {
  return (
    // <Layout theme={"fossil"}  themeColor={"#555555"}>
    //     <Seo title="TEST!!!" />
    //   <p className="p-10">Test</p>
    // </Layout>

    <Redirect noThrow to={`/what-we-do/our-work/boost`} />
  );
};

export default Boost;
